.post_media .media-group-actions button {
  transition: font-size,padding 0.3s linear;
  -webkit-transition: font-size,padding 0.3s linear;
  -o-transition: font-size,padding 0.3s linear;
}
.post_media .media-group-actions.active {
  border: 0 none;
}
.post_media .media-group-actions.active button {
  font-size: 22px;
  padding: 0;
}

.media-preview {
  position: relative;
}
.media-preview .close {
  float: none;
  position: absolute;
  right: 0;
  z-index: 999;
}