#post_addressing {
  .addressing-suggestion-title {
    color: rgba(51,51,51,1);
    font-size: 14px;
    letter-spacing: -0.2px;
    line-height: 19px;
    text-align: center;
    border-bottom: 1px solid rgba(220,220,220,1);
    padding-bottom: 5px;
    margin: 10px;
  }
  .react-autosuggest__container {
    display: inline-block;
  }
  .react-autosuggest__container .react-autosuggest__suggestions-container {
    z-index: 3 !important;
  }
  .react-autosuggest__container .chip {
    margin: 6px 20px;
  }
}
