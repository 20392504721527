.CommunityTour {
}

.tooltipJr {
  background-color: #FFF;
  border-radius: 3px;
  padding: 20px;
  width: 500px;
  max-width: 500px;
  min-width: 300px;

  button {
    border-radius: 2px;
    -webkit-appearance: none;
  }

  button.btnClose {
    float: right;
    position: relative;
    top: -3px;
    left: 3px;
    font-size: 16px;
    border: 0px;
    background: transparent;
  }

  .footer {
    padding-top: 20px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }

  .footer.centered {
    justify-content: center !important;
  }
}

@media screen and (max-width: 1100px) {
  .tooltipJr {
    width: calc(100vw * 0.42);
  }
  .tooltipJr div.content {
    font-size: 12px;
  }
}

@media screen and (max-height: 700px) {
  .tooltipJr {
    width: calc(100vw * 0.30);
  }
  .tooltipJr div.content {
    font-size: 12px;
  }
}

@media screen and (max-width: 600px) {
  .btnRemindMeLater {
    font-size: 10px !important;
  }
}

.tooltipJrR {
  p {
    font-size: 0.8rem !important;
  }
}

