.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  font-family: "Open Sans", 'scmty-icons-v57', Helvetica, sans-serif;
  width: 240px;
  height: 30px;
  padding: 0;
  border: 0 none;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px !important;
}

.react-autosuggest__input:focus {
  outline: none;
}

.react-autosuggest__container--open .react-autosuggest__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  overflow: auto;
  max-height: 200px;
  position: absolute;
  top: 46px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  min-width: 300px;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
}

.react-autosuggest__suggestion:not(:first-child) {
  border-top: 1px solid #ddd;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #0C7EAF;
  color: #fff;
}
.react-autosuggest__suggestion--highlighted .highlight {
  color: #120000;
}

.suggestion-content {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
}

.suggestion-content .text {
  padding-left: 20px;
}
.highlight {
  font-weight: bold;
}