#media_geolocation .add-location {
  cursor: pointer;
}

#geolocation_data {
  border: 1px solid #E4E4E4;
  border-top: 0;
  -webkit-border-radius: 0 0 3px 3px;
  -moz-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px;
}