.fade-enter {
  opacity: 0;
  -webkit-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
  display: block !important;
}
.fade-enter-active, .fade-enter-done {
  opacity: 1;
  display: block !important;;
}
.fade-exit {
  opacity: 1;
  display: block !important;;
}
.fade-exit-active {
  opacity: 0;
  -webkit-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
  display: block !important;;
}
.fade-exit-done {
  display: none;
}

.fade-inline {
  .fade-enter {
    display: inline-block !important;
  }
  .fade-enter-active, .fade-enter-done {
    display: inline-block !important;;
  }
  .fade-exit {
    display: inline-block !important;;
  }
  .fade-exit-active {
    display: inline-block !important;;
  }
}

.fade-in-enter {
  opacity: 0;
  -webkit-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
  display: block !important;
}
.fade-in-enter-active, .fade-in-enter-done {
  opacity: 1;
  display: block !important;;
}
.fade-in-exit, .fade-exit-active, .fade-exit-done {
  display: none;
}

.fade-inline {
  .fade-in-enter {
    display: inline-block !important;
  }
  .fade-in-enter-active, .fade-in-enter-done {
    display: inline-block !important;;
  }
  .fade-in-exit {
    display: inline-block !important;;
  }
  .fade-in-exit-active {
    display: inline-block !important;;
  }
}